<template>
  <v-textarea v-model="value" v-bind="bind" auto-grow rows="2" />
</template>

<script lang="ts" setup>
import {
  appFormInputProperties,
  useAppFormInput,
} from "../composables/form-input.hook";

const properties = defineProps({
  ...appFormInputProperties,
});

const { value, bind } = useAppFormInput(properties);
</script>
